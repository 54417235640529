<template>
  <div class="news-events">
    <div class="news-events__wrapper">
      <Column class="news-events__limiter">
        <Row align="center"
             class="news-events__title-holder"
             justify="between">
          <h1 class="news-events__title">Новости и события</h1>
          <Row align="center"
               class="news-events__see-all">
            <router-link class="news-events__see-all-text"
                         to="/news">{{$viewport.desktop ? 'смотреть ' : ''}}все
            </router-link>
            <Icon class="news-events__see-all-icon"
                  viewport="0 0 20 20"
                  :rotate="90"
                  xlink="arrow-navigation"/>
          </Row>
        </Row>
      </Column>
      <Loader v-if="$contents && $contents.data.length === 0"/>
      <Slider :items="$contents.data"
              :indentation="$viewport.desktop ? 30 : 8"
              :count="$viewport.desktop ? 3 : 1"
              component="Card"
              limiter
              v-else/>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Contents',
  props: {
    cityId: {
      default: null,
    },
  },
  methods: {
    getParams() {
      const params = {
        params: {
          sort: 'promo',
        },
      };

      if (this.cityId) {
        params.clear = true;
        params.params.city_id = this.cityId;
      }

      return params;
    },
  },
  created() {
    this.$store.dispatch('GET_CONTENTS_FROM_SERVER_BY_PARAMS', this.getParams());
  },
  watch: {
    $city() {
      this.$getNewsByCity(this.cityId);
    },
  },
};
</script>
